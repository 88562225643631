// extracted by mini-css-extract-plugin
export var badge = "HeroModule-module--badge--fb0f7";
export var badgesWraper = "HeroModule-module--badgesWraper--4f28c";
export var block = "HeroModule-module--block--97df7";
export var bottomContent = "HeroModule-module--bottomContent--90d8c";
export var bottomContentWrapper = "HeroModule-module--bottomContentWrapper--2009b";
export var buttonsWrapper = "HeroModule-module--buttonsWrapper--3d18d";
export var date = "HeroModule-module--date--fca55";
export var infoText = "HeroModule-module--infoText--6d586";
export var leftCol = "HeroModule-module--leftCol--f03bb";
export var lines = "HeroModule-module--lines--c713e";
export var title = "HeroModule-module--title--ebc1d";